exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculate-js": () => import("./../../../src/pages/calculate.js" /* webpackChunkName: "component---src-pages-calculate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontor-js": () => import("./../../../src/pages/kontor.js" /* webpackChunkName: "component---src-pages-kontor-js" */),
  "component---src-pages-laddboxar-js": () => import("./../../../src/pages/laddboxar.js" /* webpackChunkName: "component---src-pages-laddboxar-js" */),
  "component---src-pages-produkter-js": () => import("./../../../src/pages/produkter.js" /* webpackChunkName: "component---src-pages-produkter-js" */),
  "component---src-pages-sa-funkar-det-js": () => import("./../../../src/pages/sa-funkar-det.js" /* webpackChunkName: "component---src-pages-sa-funkar-det-js" */),
  "component---src-pages-tack-js": () => import("./../../../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */),
  "component---src-pages-vart-arbete-js": () => import("./../../../src/pages/vart-arbete.js" /* webpackChunkName: "component---src-pages-vart-arbete-js" */)
}

