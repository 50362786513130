import { Box } from "@mui/material";
import React from "react";
import NavBar from "./components/nav-bar";
import TopLayout from "./components/top-layout";
import theme from "./theme";

export default function wrapWithProvider({ element }) {
  return (
    <TopLayout theme={theme}>
      <NavBar></NavBar>
      <Box sx={{ mt: { xs: 0, md: 0 } }}>{element}</Box>
    </TopLayout>
  );
}
